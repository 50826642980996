<template>
  <MatchMedia v-slot="{ mobile, tablet }">
    <div class="container">
      <p class="header">Apeirox Airdrop Claim</p>
      <div class="this">
        <div class="participants">
          {{ total_participants }} persons participated
        </div>
        <div class="buttons">
          <button class="btn primary" @click.prevent="viewspreadsheet()">
            See spreadsheet
          </button>
          <button class="btn primary" @click.prevent="openUpdateWallet()">
            Update wallet
          </button>
          <button class="btn primary" @click.prevent="openAlternativeClaim()">
            Alternative claim
          </button>
        </div>
      </div>
      <div class="contentDiv">
        <div class="firstDiv no-border">
          <div class="text-participation">Confirm Participation</div>

          <div class="form-participation">
            <div class="button-wrapper">
              <input
                type="text"
                placeholder="enter email, wallet address or telegram username"
                v-model="user_info_textfield"
              />
            </div>

            <div class="button-wrapper">
              <button
                class="btn active padd-button"
                @click.prevent="checkinfo()"
                v-if="tablet"
              >
                import <span v-show="airdrop_flag">another</span> airdrop info
              </button>
              <button
                class="btn active padd-button"
                @click.prevent="checkinfo()"
                v-if="mobile"
              >
                import <span v-show="airdrop_flag">another</span> info
              </button>
            </div>
          </div>

          <div v-show="airdrop_flag" class="airdrop-info">
            <div class="table-div">
              <div class="table-label">Wallet address:</div>
              <div class="table-content-dark">{{ wallet_address }}</div>
            </div>
            <div class="table-div">
              <div class="table-label">Total refferals: {{ ref_count }}</div>
            </div>
            <div class="table-div">
              <div class="table-content">Balance: {{ balance }}</div>
            </div>
            <!-- <div class="wallet">
              
              <span class="wallet-text"></span>
            </div>
            <div class="ref-count"> </div>
            <div class="balance">
               <span class="balance-text"></span>
            </div> -->
            <button
              class="btn active button-margin"
              @click.prevent="connectwallet()"
              v-show="!wallet_connected"
            >
              connect wallet
            </button>
            <div class="wallet_connected" v-show="wallet_connected">
              Wallet connected:
              <span class="balance-text">{{ wallet_connected }}</span>
              <button
                class="btn active padd-left"
                @click.prevent="removetwallet()"
              >
                remove
              </button>
            </div>

            <p class="padd-top">
              Stay Tuned for updates on Apeirox’s Swap and P2P
            </p>
          </div>
        </div>

        <div class="firstDiv">
          <p>
            Please don't send fees more than once. Please we beg of you. If for
            any reason you checked your airdrop wallet and couldn't find your
            token after payment, please be patient, you will get it.
          </p>
          <br />
          <button
            class="btn"
            v-bind:class="{ active: wallet_connected && balance }"
            @click.prevent="claimairdrop()"
          >
            <div class="spinner-border" role="status" v-show="claiming">
              <span class="sr-only"></span>
            </div>
            <span v-show="!claiming">claim airdrop</span>
          </button>
          <p class="padd-top">{{ balance }} APX</p>
          <p>
            Please note, payment will be made to the wallet registered with the
            airdrop. Making the withdrawal can happen with any BSC wallet and
            the fee is usually less than 0.22$.
          </p>

          <p>
            If this is not comfortable for you, you can wait for the team to
            either have the funds to send airdrop to your wallet automatically
            or you may claim it when it is most useful on the ApeiroX exchange,
            when we launch.
          </p>
          <br />
        </div>
      </div>
      <div class="claim-in-progress" v-show="claiming">
        <div class="claim-container">
          <div class="spinner-border claim-mobile" role="status">
            <span class="sr-only"></span>
          </div>
          <div class="claim-padd-left">
            Do not worry, your transaction will be processed and won't take up
            to 3 hours.
          </div>
        </div>
      </div>
      <div class="claim-in-progress" v-show="claimingDisclaimer">
        <div class="claim-container">
          <div class="claim-padd-left">
            Please don't send fee more than once. Please, it is not necessary.
            If for any reason you checked your airdrop wallet and couldn't find
            your token yet, please be patient, you will get it in less than an
            hour.
          </div>
        </div>
      </div>

      <div class="at-bottom"></div>
    </div>
  </MatchMedia>
</template>

<script>
import { mapState } from "vuex";
import Moralis from "moralis";
import { MatchMedia } from "vue-component-media-queries";

Moralis.initialize("X8xDsHSFPI1dZCevLLureeYdnmoCYyaNXxNzEDqz"); // Application id from moralis.io
Moralis.serverURL = "https://e8x4mucsxy8c.moralisweb3.com:2053/server"; //Server url from moralis.io

export default {
  data: function () {
    return {
      user_info_textfield: "",
      connected: localStorage.getItem("walletAddress")
        ? localStorage.getItem("walletAddress")
        : "",
      claim: false,
      claimDisclaimer: false,
    };
  },
  components: {
    MatchMedia,
  },
  methods: {
    openUpdateWallet() {
      this.$router.push("/update-info");
    },
    openAlternativeClaim() {
      this.$router.push("/alternative");
    },
    checkinfo() {
      if (!this.user_info_textfield) {
        return this.$toasted.error(
          "Please enter either your wallet address, telegram username or email to confirm participation"
        );
      }
      this.$store
        .dispatch("chechAirdropInfo", {
          user_info_textfield: this.user_info_textfield,
        })
        .then((response) => {
          let res = "";
          if (response.data.balance == 0) {
            res = "Your token has been sent to your wallet already";
          } else {
            res =
              "Congrats, you earned " + response.data.balance + " APX tokens";
          }
          this.$toasted.success(res);
        })
        .catch((error) => {
          this.handleAxiosErrors(error);
        });
    },
    connectwallet() {
      this.login();
    },
    claimairdrop() {
      if (!this.wallet_connected) {
        return this.$toasted.error(
          "Please click on 'connect wallet' to be able to claim"
        );
      }
      return this.gasFee();
    },
    viewspreadsheet() {
      window.open(
        "https://docs.google.com/spreadsheets/d/1zhvdIaWPNc27CmioRzT3Dpjz0rOzZj19/",
        "_blank"
      );
    },
    async login() {
      try {
        let user = Moralis.User.current();
        user = await Moralis.authenticate();
        localStorage.setItem("walletAddress", user.attributes.ethAddress);
        this.connected = user.attributes.ethAddress;
        return this.$toasted.success(
          "Your wallet has been successfully connected"
        );
      } catch {
        return "You do not have metamask installed or perhaps no BSC wallet to connect, please install metamask extension and try again";
      }
    },
    async removetwallet() {
      await Moralis.User.logOut();
      this.connected = "";
      localStorage.removeItem("walletAddress");
      return this.$toasted.success("Wallet has been removed successfully");
    },
    async gasFee() {
      try {
        this.claim = true;
        const options = {
          type: "native",
          amount: Moralis.Units.ETH("0.0006"),
          receiver: "0x3864C451E10ba355021144375988EE9C241D5A9e",
        };
        let result = await Moralis.transfer(options);

        if (result.status) {
          this.$store
            .dispatch("submitPaymentByVisitor", {
              wallet_address: this.wallet_address,
              visitor_wallet: this.connected,
              transaction_hash: result.transactionHash,
              status: 1,
            })
            .then(() => {
              setTimeout(() => {
                this.sendaperioxtoken(this.wallet_address);
              }, 60000);
              this.$toasted.success(
                "Your token transfer is already been processed"
              );
            })
            .catch((error) => {
              this.handleAxiosErrors(error);
            });

          this.claimDisclaimer = true;
          setTimeout(() => {
            this.claimDisclaimer = false;
          }, 10000);
        }
      } catch (error) {
        this.claim = false;
        return this.$toasted.error(error.message);
      }
    },
    async sendaperioxtoken(walletaddrz) {
      try {
        let result = await Moralis.Cloud.run("apeiroxtokens", {
          walletaddress: walletaddrz,
        });

        if (result.transactionHash) {
          this.$store
            .dispatch("submitPaymentToWallet", {
              wallet_address: this.wallet_address,
              visitor_wallet: this.connected,
              transaction_hash: result.transactionHash,
              status: result.status ? 1 : 0,
            })
            .then(() => {
              this.claim = false;
              this.$toasted.success(
                "Congratulations, your token has been sent successfully"
              );
            })
            .catch(() => {
              this.claim = false;
              // this.handleAxiosErrors(error);
            });
        }
      } catch (error) {
        this.claim = false;
        // return this.$toasted.warning("Your transaction is processing");
      }
    },
  },
  computed: {
    ...mapState([
      "airdrop_flag",
      "balance",
      "ref_count",
      "wallet_address",
      "total_participants",
    ]),
    wallet_connected() {
      return this.connected;
    },
    claiming() {
      return this.claim;
    },
    claimingDisclaimer() {
      return this.claimDisclaimer;
    },
  },
  mounted() {
    this.$store.dispatch("getTotalParticipants").catch((error) => {
      this.handleAxiosErrors(error);
    });
  },
};
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(to right, #000a16, #000d1d);
  border-radius: 20px;
  color: white;
  padding: 30px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: calc(100% - 40px);
}
.table-div {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.table-label,
.table-content,
.table-content-dark {
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  padding: 5px;
  word-break: break-all;
  word-wrap: break-word;
}
.table-content-dark {
  background: #080808;
}
.table-label {
  background: #011c3d;
}
.table-content {
  background: #0e0e0e;
}
.this {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}
.contentDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.firstDiv {
  border: 1px solid rgb(255, 255, 255);
  border-radius: 15px;
  padding: 30px 20px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 82px);
  position: relative;
}

.no-border {
  border: 0;
  align-items: flex-start;
  left: 0;
}
.text-participation,
.form-participation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.text-participation {
  margin-bottom: 10px;
}
.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}
.claim-in-progress {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.claim-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  border-radius: 8px;
  background: #f0f0f0;
  color: #080808;
  font-size: 16;
  padding: 10px;
  margin-bottom: 20px;
  margin: 20px auto;
}
.btn {
  background: #c4c4c4;
  outline: none;
  border: none;
  border-radius: 79px;
  color: #080808;
  cursor: pointer;
  font-size: 14px;
}
.active {
  background: #f59e0b;
  color: #fff;
}
.primary {
  background: rgb(41, 154, 247);
  color: #fff;
  margin-right: 10px;
}
.btn:hover {
  background: #ffffff;
  outline: none;
  border: 0px solid #f59e0b;
  border-radius: 79px;
  color: #f59e0b;
  cursor: pointer;
}
.padd {
  margin: 20px 0;
}
.padd-top {
  padding-top: 20px;
}
.airdrop-info {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.padd-left,
.claim-padd-left {
  margin-left: 10px;
}
.wallet,
.ref-count,
.balance,
.wallet_connected {
  width: 100%;
  text-align: center;
}
.wallet_connected,
.button-margin {
  margin-top: 20px;
}
.wallet-text,
.balance-text {
  word-break: break-all;
  word-wrap: break-word;
}
.participants {
  font-size: 18px;
  margin-bottom: 10px;
}

.header {
  font-size: 20px;
}

@media (min-width: 768px) {
  .btn {
    font-size: 15px;
  }
}

@media (min-width: 1040px) {
  .contentDiv {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
  }

  .firstDiv {
    width: calc(50% - 80px);
    /* min-height: 576px; */
  }

  .btn {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .table-div {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .container {
    width: calc(100% - 10px);
    padding: 30px 5px 30px 5px;
    margin: 0;
    box-shadow: none;
    border-radius: 0;
  }
  .contentDiv {
    padding: 0;
    margin: 0;
    width: 100%;
  }
  .firstDiv {
    width: calc(100% - 20px);
    padding: 30px 10px;
    margin: 20px 0;
    border-radius: 0;
    border-right: 0;
    border-left: 0;
  }
}

@media (max-width: 440px) {
  .claim-mobile {
    display: none;
  }

  .claim-padd-left {
    margin-left: 0;
  }
}

@media (min-width: 401px) {
  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 400px) {
  .buttons {
    display: block;
    width: 100%;
    flex-direction: column;
  }
  .primary {
    width: 100%;
    margin-top: 10px;
  }
}

@media (max-width: 297px) {
  .btn {
    font-size: 13px;
  }
}
</style>
