import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueToastr from "vue-toastr";
import Toasted from "vue-toasted";
Vue.use(Toasted, {
  duration: 5000,
  position: "top-center",
});
Vue.use(VueToastr, {
  defaultTimeout: 10000,
  defaultProgressBar: false,
  defaultProgressBarValue: 0,
  defaultPosition: "toast-top-center",
  defaultCloseOnHover: false,
  defaultClassNames: ["animated", "zoomInDown"],
});

import handleAxiosErrors from "./mixins/handle-axios-errors";
Vue.mixin(handleAxiosErrors);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
