<template>
  <MatchMedia v-slot="{ mobile, tablet }">
    <div class="apx-container" v-if="onEmailPage">
      <h4 class="header" v-if="tablet">Apeirox Airdrop Wallet Update</h4>
      <h4 class="header" v-if="mobile">Airdrop Wallet Update</h4>
      <div class="central-form">
        <label v-if="tablet">Enter your email used for the airdrop</label>
        <label v-if="mobile">Enter your email</label>
        <input type="email" class="form-input" v-model="email_address" />
        <button class="btn btn-block" @click.prevent="sendToken()">
          continue
        </button>
      </div>
    </div>

    <div class="apx-container" v-if="onTokenPage">
      <h4 class="header" v-if="tablet">Apeirox Airdrop Wallet Update</h4>
      <h4 class="header" v-if="mobile">Airdrop Wallet Update</h4>
      <div class="central-form">
        <label v-if="tablet">Enter token sent to your email</label>
        <label v-if="mobile">Enter token</label>
        <input type="text" class="form-input" v-model="token" />
        <button class="btn btn-block" @click.prevent="confirmToken()">
          continue
        </button>
        Please check your email to see if your token arrived, if not
        <span class="resend" @click.prevent="sendToken()"
          >click here to resend</span
        >
      </div>
    </div>

    <div class="apx-container" v-if="onChangeWalletPage">
      <h4 class="header" v-if="tablet">Apeirox Airdrop Wallet Update</h4>
      <h4 class="header" v-if="mobile">Airdrop Wallet Update</h4>
      <div class="central-form">
        <label v-if="tablet">Enter your new wallet address</label>
        <label v-if="mobile">Enter new wallet</label>
        <input type="text" class="form-input" v-model="wallet" />
        <button class="btn btn-block" @click.prevent="changeWallet()">
          change wallet
        </button>
      </div>
    </div>

    <div class="apx-container" v-if="onCompletedPage">
      <h4 class="header" v-if="tablet">Apeirox Airdrop Wallet Updated!</h4>
      <h4 class="header" v-if="mobile">Airdrop Wallet Updated!</h4>
      <div class="central-form">
        <p>Congratulations, your wallet has been updated successfully</p>
        <p class="resend" @click.prevent="goToClaimAirdrop()">
          Click here to claim your airdrop
        </p>
      </div>
    </div>
  </MatchMedia>
</template>

<script>
import { MatchMedia } from "vue-component-media-queries";

export default {
  components: {
    MatchMedia,
  },
  name: "ChangeWallet",
  data: function () {
    return {
      email_address: "",
      token: "",
      wallet: "",
      onEmailPage: true,
      onTokenPage: false,
      onChangeWalletPage: false,
      onCompletedPage: false,
    };
  },
  methods: {
    validateEmail(email) {
      const re =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))*$/;
      return re.test(email);
    },
    sendToken() {
      if (!this.validateEmail(this.email_address)) {
        return this.$toasted.error("Please enter a valid email");
      }

      this.$store
        .dispatch("sendToken", {
          email_address: this.email_address,
        })
        .then(() => {
          this.onEmailPage = false;
          this.onTokenPage = true;
          this.onChangeWalletPage = false;
          this.onCompletedPage = false;
          this.$toasted.success(
            "Your token has been sent to your email, please check!"
          );
        })
        .catch((error) => {
          this.handleAxiosErrors(error);
        });
    },
    goToClaimAirdrop() {
      this.$router.push("/");
    },
    confirmToken() {
      if (!this.token) {
        return this.$toasted.error(
          "Please enter the token received to proceed"
        );
      }

      this.token = this.token.toLowerCase();
      this.token = this.token.trim();

      this.$store
        .dispatch("confirmToken", {
          token: this.token,
        })
        .then(() => {
          this.onEmailPage = false;
          this.onTokenPage = false;
          this.onChangeWalletPage = true;
          this.onCompletedPage = false;
          this.$toasted.success("Token validated successfully");
        })
        .catch((error) => {
          this.handleAxiosErrors(error);
        });
    },
    changeWallet() {
      if (!this.token) {
        return this.$toasted.error(
          "Please enter the token received to proceed"
        );
      }
      if (!this.email_address) {
        return this.$toasted.error(
          "There is an issue, report this to the ApeiroX Telegram group"
        );
      }

      if (!this.wallet) {
        return this.$toasted.error("Please enter your new wallet address");
      }
      this.$store
        .dispatch("changeWallet", {
          wallet: this.wallet,
          token: this.token,
          email_address: this.email_address,
        })
        .then(() => {
          this.onEmailPage = false;
          this.onTokenPage = false;
          this.onChangeWalletPage = false;
          this.onCompletedPage = true;
          this.$toasted.success(
            "Congrats, new wallet has been saved successfully"
          );
        })
        .catch((error) => {
          this.handleAxiosErrors(error);
        });
    },
  },
  computed: {
    email() {
      return this.email_address;
    },
    tokenReceived() {
      return this.token;
    },
  },
};
</script>

<style>
.apx-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(to right, #000a16, #000d1d);
  border-radius: 20px;
  color: white;
  box-shadow: 0 4px 8px 0 rgba(131, 124, 124, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: calc(100vw - 40px);
  margin-top: 50px;
  padding-top: 30px;
  padding-bottom: 60px;
}

.header {
  padding: 0 10px;
}

.central-form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 90%;
}

label {
  margin-bottom: 10px;
}

.btn {
  padding: 10px 20px;
}

.btn-block {
  margin-top: 20px;
  margin-bottom: 20px;
}

.resend {
  color: rgb(71, 151, 255);
  cursor: pointer;
}

input[type="text"],
input[type="email"] {
  text-align: center;
  text-indent: 0;
}

@media (min-width: 768px) {
  .apx-container {
    width: 50vw;
  }
}
</style>
