<template>
  <MatchMedia v-slot="{ mobile, tablet }">
    <div class="apx-container">
      <h4 class="header" v-if="tablet">Apeirox Airdrop Alternative Claim</h4>
      <h4 class="header" v-if="mobile">ApeiroX Airdrop Claim</h4>
      <div class="central-form">
        <label v-if="tablet"
          >Enter the transaction hash of the fee you paid</label
        >
        <label v-if="mobile">Enter your transaction hash</label>
        <input type="text" class="form-input" v-model="transaction_hash" />
        <button class="btn btn-block" @click.prevent="sendHash()">
          continue
        </button>
      </div>
      <p v-if="notSubmitted">
        This method of claiming the airdrop is for those having difficulty
        connecting their wallet to our dapp. It takes maximum of 3 hours for
        your wallet to be credited. Do not pay twice.
      </p>
      <p v-if="!notSubmitted">{{ submittedMessage }}</p>
      <p>
        Please note, do not make payment from a wallet that is not your airdrop
        wallet, you will lose the fee. If you don't have access to your airdrop
        wallet, use this link to change your wallet address to the one you can
        access.
      </p>
      <p class="break-word">
        Send 0.0006 bnb to 0x3864C451E10ba355021144375988EE9C241D5A9e, once
        payment is completed, copy the hash and paste in the field above.
        <a
          href="https://coinmarketcap.com/alexandria/article/how-to-use-bscscan"
          target="_blank"
          >See here to learn how to copy transaction hash from bscscan</a
        >
      </p>
      <button class="btn btn-block" @click.prevent="visitWebsite()">
        Visit website to learn about ApeiroX
      </button>
    </div>
  </MatchMedia>
</template>

<script>
import { MatchMedia } from "vue-component-media-queries";

export default {
  components: {
    MatchMedia,
  },
  name: "AlternativePayment",
  data: function () {
    return {
      transaction_hash: "",
      submittedMessage: "",
      notSubmitted: true,
    };
  },
  methods: {
    visitWebsite() {
      // windows.location.href = "https://apeirox.com"
      window.open("https://apeirox.com", "_top");
    },
    sendHash() {
      if (!this.transaction_hash) {
        return this.$toasted.error("Please enter a transaction hash");
      }

      this.$store
        .dispatch("sendHash", {
          transaction_hash: this.transaction_hash,
        })
        .then(() => {
          this.notSubmitted = false;
          this.submittedMessage =
            "Your hash has been submitted successfully, it may take a while before you receive the token. It is processed in batches and can take up to a maximum of 3 hours for you to get credited.";
          this.$toasted.success("Your hash has been submitted successfully");
        })
        .catch((error) => {
          this.handleAxiosErrors(error);
        });
    },
  },
};
</script>

<style>
.apx-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(to right, #000a16, #000d1d);
  border-radius: 20px;
  color: white;
  box-shadow: 0 4px 8px 0 rgba(131, 124, 124, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: calc(100vw - 40px);
  margin-top: 50px;
  padding-top: 30px;
  padding-bottom: 60px;
}

.break-word {
  word-break: break-all;
  word-wrap: break-word;
}

.header {
  padding: 0 10px;
}

.central-form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 90%;
}

p {
  padding: 20px;
}

label {
  margin-bottom: 10px;
}

.btn {
  padding: 10px 20px;
}

.btn-block {
  margin-top: 20px;
  margin-bottom: 20px;
}

.resend {
  color: rgb(71, 151, 255);
  cursor: pointer;
}

input[type="text"],
input[type="email"] {
  text-align: center;
  text-indent: 0;
}

@media (min-width: 768px) {
  .apx-container {
    width: 50vw;
  }
}
</style>
