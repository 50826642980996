import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import Send from "../views/Send.vue";
import HelloWorld from "@/components/HelloWorld.vue";
import ChangeWallet from "@/components/ChangeWallet.vue";
import AlternativePayment from "@/components/AlternativePayment.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HelloWorld",
    component: HelloWorld,
  },
  {
    path: "/send",
    name: "Send",
    component: Send,
  },
  {
    path: "/update-info",
    name: "ChangeWallet",
    component: ChangeWallet,
  },
  {
    path: "/alternative",
    name: "AlternativePayment",
    component: AlternativePayment,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
