import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
axios.defaults.withCredentials = false;
axios.defaults.baseURL = "https://apeirox.bumasys.com/api/";
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    return Promise.reject(error);
  }
});

Vue.use(Vuex);

import createPersistedState from "vuex-persistedstate";

export default new Vuex.Store({
  state: {
    airdrop_flag: false,
    wallet_address: "",
    balance: 0,
    ref_count: 0,
    total_participants: 0,
    nothing: false,
    unpaid: [],
    sent: false,
    token: "",
    email_address: "",
    wallet_change_page: 0,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setEmailAddress(state, email_address) {
      state.email_address = email_address;
    },
    setWalletChangePage(state, page) {
      state.wallet_change_page = page;
    },
    setTotalParticipants(state, total) {
      state.total_participants = total;
    },
    setAirdropFlag(state, airdrop_flag) {
      state.airdrop_flag = airdrop_flag;
    },
    setAirdropInfo(state, airdrop_info) {
      state.wallet_address = airdrop_info.wallet_address;
      state.balance = airdrop_info.balance;
      state.ref_count = airdrop_info.ref_count;
    },
    setNothing(state, nothing) {
      state.nothing = nothing;
    },
    setBalance(state, balance) {
      state.balance = balance;
    },
    setUnpaid(state, unpaid) {
      state.unpaid = unpaid;
    },
    setSent(state, sent) {
      state.sent = sent;
    },
  },
  actions: {
    chechAirdropInfo({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            "get_airdrop_info?wallet_address=" + credentials.user_info_textfield
          )
          .then((response) => {
            commit("setAirdropFlag", true);
            commit("setAirdropInfo", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response) {
              reject(error.response.data);
            } else {
              reject(error);
            }
          });
      });
    },
    getUnpaidWallets({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("send_pending_payment")
          .then((response) => {
            commit("setUnpaid", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response) {
              reject(error.response.data);
            } else {
              reject(error);
            }
          });
      });
    },
    sendHash({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post("send_hash", credentials)
          .then((response) => {
            commit("setNothing", true);
            resolve(response);
          })
          .catch((error) => {
            if (error.response) {
              reject(error.response.data);
            } else {
              reject(error);
            }
          });
      });
    },
    sendToken({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        commit("setEmailAddress", credentials.email_address);
        axios
          .post("get_update_token", credentials)
          .then((response) => {
            commit("setWalletChangePage", 1);
            resolve(response);
          })
          .catch((error) => {
            if (error.response) {
              reject(error.response.data);
            } else {
              reject(error);
            }
          });
      });
    },
    confirmToken({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post("confirm_token", credentials)
          .then((response) => {
            commit("setWalletChangePage", 2);
            resolve(response);
          })
          .catch((error) => {
            if (error.response) {
              reject(error.response.data);
            } else {
              reject(error);
            }
          });
      });
    },
    changeWallet({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post("change_wallet", credentials)
          .then((response) => {
            commit("setWalletChangePage", 3);
            resolve(response);
          })
          .catch((error) => {
            if (error.response) {
              reject(error.response.data);
            } else {
              reject(error);
            }
          });
      });
    },
    sendPayment({ commit, resolve }, credentials) {
      return new Promise(() => {
        axios
          .get(
            "https://e8x4mucsxy8c.moralisweb3.com:2053/server/functions/reapeiroxtokens?_ApplicationId=X8xDsHSFPI1dZCevLLureeYdnmoCYyaNXxNzEDqz&walletaddress=" +
              credentials.wallet_address
          )
          .then((response) => {
            console.log(response);
            commit("setSent", true);
            resolve(response);
          });
      });
    },
    getTotalParticipants({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("check_total_participants")
          .then((response) => {
            commit("setTotalParticipants", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response) {
              reject(error.response.data);
            } else {
              reject(error);
            }
          });
      });
    },
    submitPaymentByVisitor({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post("submit_payment_by_visitor", credentials)
          .then((response) => {
            commit("setNothing", true);
            resolve(response);
          })
          .catch((error) => {
            if (error.response) {
              reject(error.response.data);
            } else {
              reject(error);
            }
          });
      });
    },
    submitPaymentToWallet({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post("submit_payment_to_wallet", credentials)
          .then((response) => {
            if (credentials.status == 1) {
              commit("setBalance", 0);
            }
            resolve(response);
          })
          .catch((error) => {
            if (error.response) {
              reject(error.response.data);
            } else {
              reject(error);
            }
          });
      });
    },
  },
  modules: {},
  plugins: [createPersistedState()],
  getters: {
    airdrop_flag: (state) => {
      return state.airdrop_flag;
    },
    wallet_address: (state) => {
      return state.wallet_address;
    },
    ref_count: (state) => {
      return state.ref_count;
    },
    balance: (state) => {
      return state.balance;
    },
    total_participants: (state) => {
      return state.total_participants;
    },
  },
});
