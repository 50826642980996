<template>
  <SendPendingPayments />
</template>

<script>
// @ is an alias to /src
import SendPendingPayments from "@/components/SendPendingPayments.vue";

export default {
  name: "Send",
  components: {
    SendPendingPayments,
  },
};
</script>
