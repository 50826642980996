<template>
  <MatchMedia v-slot="{ mobile, tablet }">
    <div class="container" style="width: 100%; margin-top: 50px">
      <div class="firstDiv" style="width: calc(100% - 40px)">
        <div class="text-participation">Send pending payments</div>
        <div class="form-participation">
          <div class="button-wrapper">
            <button
              v-if="tablet"
              class="btn active padd-button"
              @click.prevent="sendpayments()"
            >
              Send pending payments
            </button>
            <button
              v-if="mobile"
              class="btn active padd-button"
              @click.prevent="sendpayments()"
            >
              Send payments
            </button>
          </div>
        </div>
      </div>
    </div>
  </MatchMedia>
</template>

<script>
import Moralis from "moralis";
import { MatchMedia } from "vue-component-media-queries";

Moralis.initialize("X8xDsHSFPI1dZCevLLureeYdnmoCYyaNXxNzEDqz"); // Application id from moralis.io
Moralis.serverURL = "https://e8x4mucsxy8c.moralisweb3.com:2053/server"; //Server url from moralis.io

export default {
  name: "SendPendingPayments",
  components: {
    MatchMedia,
  },
  data: function () {
    return {
      unpaidWallets: [],
    };
  },
  methods: {
    sendpayments() {
      var apeiroxdfg = [];
      this.$store
        .dispatch("getUnpaidWallets")
        .then((response) => {
          apeiroxdfg = response.data;
          this.$toasted.success("fetched records and sending");
          this.sendapeiroxairdrop(apeiroxdfg);
        })
        .catch((error) => {
          this.handleAxiosErrors(error);
        });
    },
    async sendapeiroxairdrop(apeiroxdfg) {
      var walletaddrz = 0;

      for (var i = 0; i < apeiroxdfg.length; i++) {
        walletaddrz = apeiroxdfg[i].wallet_address;

        let apeiroxtokenz = await Moralis.Cloud.run("reapeiroxtokens", {
          walletaddress: walletaddrz,
        });

        console.log(apeiroxtokenz);
        this.$toasted.success("finished sending");
      }
    },
  },
};
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(to right, #000a16, #000d1d);
  border-radius: 20px;
  color: white;
  padding: 30px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: calc(100vw - 40px);
}
.firstDiv {
  border: 1px solid rgb(255, 255, 255);
  border-radius: 15px;
  padding: 30px 20px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 82px);
  position: relative;
}
.text-participation,
.form-participation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.text-participation {
  margin-bottom: 10px;
}
.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}
.btn {
  background: #c4c4c4;
  outline: none;
  border: none;
  border-radius: 79px;
  /* padding: 10px 20px; */
  color: #080808;
  cursor: pointer;
  font-size: 14px;
}
.active {
  background: #f59e0b;
  color: #fff;
}
.primary {
  background: rgb(41, 154, 247);
  color: #fff;
}
.btn:hover {
  background: #ffffff;
  outline: none;
  border: 0px solid #f59e0b;
  border-radius: 79px;
  color: #f59e0b;
  cursor: pointer;
}
.padd {
  margin: 20px 0;
}
.padd-top {
  padding-top: 20px;
}
.header {
  font-size: 20px;
}

@media (min-width: 768px) {
  .firstDiv {
    width: 100%;
  }
}

@media (max-width: 440px) {
  .claim-mobile {
    display: none;
  }

  .claim-padd-left {
    margin-left: 0;
  }
}

@media (max-width: 500px) {
  .container {
    width: calc(100% - 10px);
    padding: 30px 5px 30px 5px;
    margin: 0;
    box-shadow: none;
    border-radius: 0;
  }
  .contentDiv {
    padding: 0;
    margin: 0;
    width: 100%;
  }
  .firstDiv {
    width: calc(100% - 20px);
    padding: 30px 10px;
    margin: 20px 0;
    border-radius: 0;
    border-right: 0;
    border-left: 0;
  }
}

@media (max-width: 297px) {
  .btn {
    font-size: 13px;
  }
}
</style>
