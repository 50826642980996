<template>
  <MediaQueryProvider
    :queries="{ mobile: '(max-width: 450px)', tablet: '(min-width: 451px)' }"
  >
    <div>
      <VueToastr ref="toastr" />
      <router-view />
    </div>
  </MediaQueryProvider>
</template>

<script>
import VueToastr from "vue-toastr";
import { MediaQueryProvider } from "vue-component-media-queries";

export default {
  components: {
    VueToastr,
    MediaQueryProvider,
  },
};
</script>

<style>
@font-face {
  font-family: "Axiforma";
  src: local("Axiforma"),
    url("./fonts/Kastelov - Axiforma SemiBold.otf") format("truetype");
}
body {
  background: linear-gradient(to right, #010a15, #01142b);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  width: 100%;
  font-family: Axiforma;
  margin: 0;
}

input[type="text"],
input[type="email"] {
  width: calc(100% - 2px);
  height: 40px;
  text-indent: 10px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 16px;
}
</style>
